import { combineReducers, configureStore } from "@reduxjs/toolkit";

import userReducer from "./user";
import manualUserReducer from "./manualSelectUser";

const rootReducer = combineReducers({
  user: userReducer,
  manualUser: manualUserReducer,
});

export default configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});
