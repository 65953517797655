import { ButtonSecondary } from "@givesafe/design-system-react";
import { Link } from "react-router-dom";

// export const PhoneOrEmailQuestion = () => {
//   const { send } = useContext(RegistrationMachineContext);
//   const onSelect = (method) => {
//     send({ type: "SELECT_METHOD", method });
//   };
//   const onSelectNeither = () => send("SELECT_NEITHER");
//   return (
//     <div>
//       Did you register with a phone number or email address?
//       <div>
//         <ButtonCTA2
//           focused
//           type="button"
//           onClick={() => onSelect("phoneNumber")}
//         >
//           I registered with a phone number
//         </ButtonCTA2>
//         <ButtonCTA2 focused type="button" onClick={() => onSelect("email")}>
//           I registered with an email address
//         </ButtonCTA2>
//         <ButtonCTA2 focused type="button" onClick={onSelectNeither}>
//           I didn&apos;t register with any.
//         </ButtonCTA2>
//       </div>
//     </div>
//   );
// };

const NoMethod = () => (
  <div>
    <h1>
      Sorry, we aren&apos;t able to create a Samaritan Member account for you.
    </h1>
    <br />
    <p>
      Registration for a Samaritan member account requires that you have
      provided an email or phone number during your Samaritan Member intake.
    </p>
    <br />
    <p>
      Contact your lifecare provider to add an email and/or phone number to your
      Samaritan Member profile.
    </p>
    <br />
    <div>
      <Link to="/">
        <ButtonSecondary light>return to home</ButtonSecondary>
      </Link>
    </div>
  </div>
);
export default NoMethod;
