import { useMutation } from "react-query";

import HttpClient from "../../httpClient";
import authManagerInstance from "../../auth";


const useLogin = () =>
  useMutation(
    ({ lastName, formattedDate, password }) =>
      HttpClient.memberLogin
        .post("/sign_in", null, {
          params: {
            member: {
              // date_of_birth: "Sun, 03 May 1998",
              date_of_birth: formattedDate,
              pii_last_name: encodeURIComponent(lastName),
              password,
            },
            auth_type: "GIVESAFE_AUTH",
          },
        })
        .then((res) => res.data),
    {
      onError: (error) => {
      },
      onSuccess: (data) => {
        // console.log("setting user as ", data)
        authManagerInstance.setUser(data);
      },
    }
  );


export default useLogin;
