import {
  ButtonCTA2,
  LoadingCircle,
  Input,
} from "@givesafe/design-system-react";
import { useContext, useEffect, useState } from "react";
import { RegistrationMachineContext } from "../registrationMachine";
import useValidateAccessCode from "../../../hooks/api/registration/useValidateAccessCode";

const CodeInputQuestion = () => {
  const {
    send,
    current: { context },
  } = useContext(RegistrationMachineContext);
  const [code, setCode] = useState("");
  const {
    mutate: validate,
    isLoading,
    isSuccess,
    isIdle,
    isError,
    reset,
  } = useValidateAccessCode();

  useEffect(() => {
    if (isSuccess)
      send({
        type: "VALID",
        code,
      });
  }, [code, isSuccess, send]);

  const onChange = (e) => {
    reset();
    setCode(e.target.value.replace(/\D/g, ""));
  };

  const onSubmit = (e) => {
    if (!e.target.reportValidity()) return;

    validate({
      method: context.method,
      value: context[context.method],
      code,
    });
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter") onSubmit(e);
  };

  const buttonVariant = isError ? "error" : "default";

  return (
    <div>
      <div>
        <h1>Enter Your Access Code</h1>
        <p>
          You have been sent a six-digit access code to{" "}
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <u>{context[context.method]}</u>.
        </p>
      </div>
      <div style={{ textAlign: "center" }}>
        <Input
          autoFocus
          type="text"
          name="accessCode"
          placeholder="Enter Access Code"
          style={{ marginTop: 20 }}
          maxLength={6}
          minLength={6}
          id="accessCode"
          value={code}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
        <ButtonCTA2
          type="button"
          focused={code.length === 6}
          disabled={code.length !== 6 || isLoading}
          variant={buttonVariant}
          onClick={onSubmit}
        >
          {isIdle && "Check Code"}
          {isLoading && <LoadingCircle />}
          {isError && "Invalid Code"}
        </ButtonCTA2>
      </div>
    </div>
  );
};

export default CodeInputQuestion;
