import { ButtonCTA1 } from "@givesafe/design-system-react";
import { useContext } from "react";
import { RegistrationMachineContext } from "../registrationMachine";

const Intro = () => {
  const { send } = useContext(RegistrationMachineContext);

  const startRegistration = () => send("START");
  // const gotoLCPSignIn = () => send("PROXY_SIGN_IN");
  return (
    <div>
      <div>
        <h1>Register for a Samaritan Member Portal account</h1>
        <br />
        <p>
          The Samaritan Member Portal is the place where you can check up on the
          progress of your goal actions and needs, view any new messages
          you&apos;ve received from Samaritans, check for updates on your team
          page, and a whole lot more.
        </p>
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        <ButtonCTA1 onClick={startRegistration}>Get Started</ButtonCTA1>
      </div>
    </div>
  );
};

export default Intro;
