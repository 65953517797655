import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

const ScrollIntoView = ({ children, window }) => {
  const containerRef = useRef(null);

  useEffect(() => {
        if (containerRef.current && window.width >= 767) {
            containerRef.current.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start"
            });
        }
        // } else if (containerRef.current && window.width <= 767) {
        //     console.log("in else if")
        //     containerRef.current.scrollIntoView({
        //         behavior: "smooth",
        //     });
        // }
        
    }, [children, window.width]);

    return <div ref={containerRef}>{children}</div>;
};

ScrollIntoView.propTypes = {
    window: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired
};

export default ScrollIntoView;
