import { useMutation } from "react-query"


// TODO: implement method input validation
const useValidateMethodInput = () => useMutation("validateMethodInput", ({ method, value }) => new Promise((resolve, reject) => {
  setTimeout(() => {
    if (method === "email" && value === "a@b.c") return resolve()
    if (method === "phoneNumber" && value === "0000000000") return resolve()
    return reject()

  }, 500)
}))

export default useValidateMethodInput