import { useMutation } from "react-query"

// TODO: implement validate access code
const useValidateAccessCode = () => useMutation("validateAccessCode", ({ method, value, code }) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {

      (value === "a@b.c" || value === "0000000000") && code === "000000" ?
        resolve() : reject()
    }, 500)
  })
)

export default useValidateAccessCode