import React from "react";
import { ButtonCTA1 } from "@givesafe/design-system-react";
import PropTypes from "prop-types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import classes from "./index.module.scss";

const LoginOptionsComponent = ({ setStage, setCity, city }) => {
    const handleClickContact = () => {
        setStage("contact-inputs");
    };

    const handleClickCredentials = () => {
        setStage("credential-inputs");
    };

    return (
        <div className={classes.loginOptionsDiv}>
            <div className={classes.currentCityDiv}>
                <button
                className={classes.quickAction}
                type="button"
                aria-label="Back to cities"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setStage("cities");
                    setCity({});
                }}
                >
                <ChevronLeftIcon style={{ color: "#7378e8", height: "32px", width: "32px" }} />
                </button>
                <span style={{ marginLeft: 5 }}>{city.name}</span>
            </div>
            <div className={classes.optionsDiv}>
                <h3>How would you like to login?</h3>
                <ButtonCTA1 type="submit" invert="true" onClick={handleClickContact}>
                    Contact Information
                </ButtonCTA1>
                <ButtonCTA1 type="submit" invert="true" onClick={handleClickCredentials}>
                    Credentials
                </ButtonCTA1>
            </div>
        </div>
    );
};

LoginOptionsComponent.propTypes = {
    setStage: PropTypes.func.isRequired,
    setCity: PropTypes.func.isRequired,
    city: PropTypes.shape({
        name: PropTypes.string,
        subdomain_name: PropTypes.string,
    }).isRequired,
};

export default LoginOptionsComponent;
