import {
  ButtonCTA2,
  Input,
  LoadingCircle,
} from "@givesafe/design-system-react";
import { useContext, useEffect, useState } from "react";
import { RegistrationMachineContext } from "../registrationMachine";
import useValidateMethodInput from "../../../hooks/api/registration/useValidateMethodInput";
import formatPhoneNumber from "../../../utils/formatPhoneNumber";

import classes from "./MethodInputQuestion.module.scss";

const MethodInputQuestion = () => {
  const { send } = useContext(RegistrationMachineContext);

  return (
    <div className={classes.methodInputContainer}>
      <div>
        <h1>Provide a contact method</h1>
        <p>
          Provide an email or phone number to receive your access code. Only one
          is required. The email address or phone number must match the
          information you provided during your intake.
        </p>
      </div>
      <hr />
      <PhoneInputQuestion />
      <div style={{ textAlign: "center" }}>
        <h3>or</h3>
      </div>
      <EmailInputQuestion />
      <hr />
      <div style={{ textAlign: "center" }}>
        <ButtonCTA2 focused type="button" onClick={() => send("NO_METHOD")}>
          I didn&apos;t register with phone number or email
        </ButtonCTA2>
      </div>
    </div>
  );
};
export default MethodInputQuestion;

// its not really that complex
// eslint-disable-next-line sonarjs/cognitive-complexity
const PhoneInputQuestion = () => {
  const {
    send,
    current: {
      context: { phoneNumber },
    },
  } = useContext(RegistrationMachineContext);

  const {
    mutate: validate,
    isSuccess,
    isLoading,
    isError,
    isIdle,
    reset,
  } = useValidateMethodInput({
    method: "phoneNumber",
    value: phoneNumber,
  });

  useEffect(() => {
    if (isSuccess)
      send({
        type: "VALID",
        method: "phoneNumber",
      });
  }, [isSuccess, send]);

  const onChange = (e) => {
    reset();
    send({
      type: "SET",
      method: "phoneNumber",
      value: e.target.value.replace(/\D/g, ""),
    });
  };

  const onSubmit = (e) => {
    if (!e.target.reportValidity()) return;

    validate({
      method: "phoneNumber",
      value: phoneNumber,
    });
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter") onSubmit(e);
  };

  const isValid = phoneNumber.length === 10;

  const isButtonDisabled = !isValid || isLoading;
  const buttonVariant = isError ? "error" : "default";

  return (
    <div>
      <label htmlFor="phoneNumber">
        <h3>Input Phone Number</h3>
      </label>
      <Input
        autoFocus
        aria-label="input phone number"
        type="tel"
        name="phoneNumber"
        id="phoneNumber"
        placeholder="xxx-xxx-xxxx"
        // length in input field is 12 because of hyphens
        maxLength={12}
        minLength={12}
        required
        value={formatPhoneNumber(phoneNumber)}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
      <div style={{ textAlign: "center" }}>
        <ButtonCTA2
          disabled={isButtonDisabled}
          focused={isValid}
          variant={buttonVariant}
          type="button"
          onClick={onSubmit}
        >
          {isIdle && "Get Access Code With a Phone Number"}
          {isLoading && <LoadingCircle />}
          {isError && "This phone number is not in our records. Try again"}
        </ButtonCTA2>
      </div>
    </div>
  );
};

const EmailInputQuestion = () => {
  const {
    send,
    current: {
      context: { email },
    },
  } = useContext(RegistrationMachineContext);
  const [isValid, setValidity] = useState(false);

  const {
    mutate: validate,
    isSuccess,
    isLoading,
    isError,
    isIdle,
    reset,
  } = useValidateMethodInput({
    method: "email",
    value: email,
  });

  useEffect(() => {
    if (isSuccess)
      send({
        type: "VALID",
        method: "email",
      });
  }, [isSuccess, send]);

  const onChange = (e) => {
    reset();
    setValidity(e.target.checkValidity());
    send({
      type: "SET",
      method: "email",
      value: e.target.value,
    });
  };

  const onSubmit = (e) => {
    if (!e.target.reportValidity()) return;

    validate({ method: "email", value: email });
  };

  const onKeyUp = (e) => {
    if (e.key === "Enter") onSubmit(e);
  };

  const buttonVariant = isError ? "error" : "default";

  return (
    <div>
      <label htmlFor="email">
        <h3>Input Email</h3>
      </label>
      <Input
        type="email"
        name="email"
        id="email"
        required
        placeholder="email@email.com"
        value={email}
        onChange={onChange}
        onKeyUp={onKeyUp}
      />
      <div style={{ textAlign: "center" }}>
        <ButtonCTA2
          disabled={!isValid || isLoading}
          focused={isValid}
          variant={buttonVariant}
          type="button"
          onClick={onSubmit}
        >
          {isIdle && "Get Access Code With an Email"}
          {isLoading && <LoadingCircle />}
          {isError && "This email address is not in our records. Try again"}
        </ButtonCTA2>
      </div>
    </div>
  );
};
