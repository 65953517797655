import React, { useMemo } from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import PropTypes from "prop-types";

import CityButtons from "../../components/CityButtons/index";
import classes from "./index.module.scss";

const CityOptions = ({ errorMessage, setStage, cities, setCity, onWide }) => {
    const citySelector = useMemo(
        () => (
            <ReactVisibilitySensor 
            // onChange={(setStage("login-options"))} 
            active>
                <CityButtons
                    setCity={setCity}
                    setStage={setStage}
                    cities={cities.data || []}
                    onWide={onWide}
                />
            </ReactVisibilitySensor>
        ),
        [cities, onWide, setCity, setStage]
    ) ;
    return (
        <div>
            {errorMessage?.length > 0 &&
                <div className={classes.error} style={{ textAlign: "center" }}>{errorMessage}</div>
            }
            {citySelector}
        </div>
    );
};

const cityShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
});

CityOptions.propTypes = {
    errorMessage: PropTypes.string.isRequired,
    cities: PropTypes.arrayOf(cityShape).isRequired,
    setStage: PropTypes.func.isRequired,
    setCity: PropTypes.func.isRequired,
    onWide: PropTypes.func.isRequired,
};

export default CityOptions;