import { useRef, useState, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { useMeasure, useScroll, useWindowSize, useLocalStorage } from "react-use";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";

import classes from "./index.module.scss";
import introVideo from "../../assets/intro.webm";
import samaritanLogo from "../../assets/logo-white.png";
import useCities from "../../hooks/api/useCities";
import CityOptions from "./CityOptions";
import LoginOptionsComponent from "./LoginOptionsComponent";
import ContactInputsComponent from "./ContactInputsComponent";
import CredentialInputsComponent from "./CredentialInputsComponent";
import CurrentCity from "./CurrentCity";
import ScrollIntoView from "./SrollIntoView";

const Login = ({ errorMessage }) => {
  const [stage, setStage] = useState("cities");
  const [city, setCity] = useLocalStorage("samaritan::city", {});
  const error = errorMessage || "";

  useEffect(() => {
    if (city && city.name) {
      setStage("credential-inputs");
      // setStage("login-options");
    }
  }, [city]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "Login" });
  }, []);

  const cities = useCities();

  // conditional style state
  const [isFormWide, setFormWide] = useState(true);

  // measure window
  const window = useWindowSize();

  // measure the scrolling element
  const [measureRef, measure] = useMeasure();
  const scrollRef = useRef(null);
  const { y } = useScroll(scrollRef);

  // how much vh to scroll before mobile reaches form position
  const scrollLead = 25;
  const percentScroll = ((y * 4) / (measure.height || 1)).toFixed(2);

  const scrollToLoginForm = () => {
    loginFormDivRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const videoDivStyle = useSpring({
    transform: `translateY(${-y * 3}px)`,
    opacity: Math.max(0, 1 - percentScroll),
    config: {
      clamp: true,
      // precision: 0.001,
    },
  });

  const mobileLogoStyles = useSpring({
    top: `${Math.max(scrollLead, (2 - percentScroll * 1.5) * scrollLead) + 2}%`,
    config: {
      clamp: true,
      tension: 400,
    },
  });

  const LoginFormDivStyles = useSpring({
    width:
      window.width > 767
        ? `max(360px, ${isFormWide ? 120 : 80}%)`
        : "max(360px, 100%)",
    marginTop:
      window.width > 767
        ? "0vh"
        : `${Math.max(scrollLead, 100 - (100 - scrollLead) * percentScroll)}vh`,
    config: {
      clamp: true,
    },
  });

  const loginFormDivRef = useRef(null);
  useEffect(() => {
    const scrollTimeout = setTimeout(
      () => loginFormDivRef.current?.scrollIntoView({ behavior: "smooth" }),
      3000
    );

    scrollRef.current.addEventListener("scroll", () =>
      clearTimeout(scrollTimeout)
    );

    return () => clearTimeout(scrollTimeout);
  }, []);

  const onWide = useCallback(() => setFormWide(true), []);

  return (
    <div className={classes.root}>
      <Helmet title="Login" />
      <animated.div
        className={classes.login}
        ref={(ref) => {
          scrollRef.current = ref;
          if (ref) measureRef(ref);
        }}
        onClick={() => {
          // Only trigger scroll on mobile
          if (window.width <= 767) {
            scrollToLoginForm();
          }
        }}
      >
        <div className={classes.loginDivVideoWrapper}>
          <animated.div className={classes.loginVideoDiv} style={videoDivStyle}>
            <div className={classes.opacity} />
            <animated.video
              className={classes.loginVideo}
              autoPlay
              muted
              loop
              src={introVideo}
            />
          </animated.div>
          <div className={classes.logo}>
            <img src={samaritanLogo} alt="samaritan" height={70} />
            <div className={classes.logoCaption}>walk with, not by</div>
          </div>
        </div>
        <animated.div style={mobileLogoStyles} className={classes.mobileLogo}>
          <img src={samaritanLogo} alt="samaritan" height={70} />
          <div className={classes.logoCaption}>walk with, not by</div>
        </animated.div>
        <animated.div
          className={classes.loginFormDiv}
          style={LoginFormDivStyles}
          ref={loginFormDivRef}
        >
          <div className={classes.greetingText}>
            <div className={classes.welcomeBack}>Samaritan Member Portal</div>
            <div className={classes.partnerSignIn}>Stay up-to-date on goals, balances, and messages from supporters</div>
          </div>
          <div className={classes.divline}/>
          {stage === "cities" &&
            <CityOptions errorMessage={error} setStage={setStage} cities={cities} setCity={setCity} onWide={onWide} />
          }
          {stage === "login-options" &&
            <LoginOptionsComponent setStage={setStage} setCity={setCity} city={city} />
          }
          {stage === "contact-inputs" &&
            <div>
              <CurrentCity setStage={setStage} setCity={setCity} city={city}/>
              <ContactInputsComponent setStage={setStage} setCity={setCity} city={city} />
            </div>
          }
          {stage === "credential-inputs" && ( 
            <div>
              <CurrentCity setStage={setStage} setCity={setCity} city={city}/>
              <ScrollIntoView window={window}>  
                <CredentialInputsComponent setStage={setStage} setCity={setCity} city={city} onWide={onWide} errorMessage={error}/>
              </ScrollIntoView>
            </div>
          )}
        </animated.div>
      </animated.div>
    </div>
  );
};

Login.propTypes = {
  errorMessage: PropTypes.string.isRequired,
};

export default Login;
