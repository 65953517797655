// CurrentCity.jsx
import PropTypes from "prop-types";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import classes from "./index.module.scss";

const CurrentCity = ({ setStage, setCity, city }) => (
  <div>
    <div className={classes.currentCityDiv}>
    <button
      className={classes.quickAction}
      type="button"
      aria-label="Back to cities"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setStage("cities");
        setCity({});
      }}
    >
      <ChevronLeftIcon style={{ color: "#7378e8", height: "32px", width: "32px" }} />
    </button>
    <span style={{ marginLeft: 5 }}>{city.name}</span>
    </div>
    <div className={classes.divline}/>
  </div>
);


CurrentCity.propTypes = {
  setStage: PropTypes.func.isRequired,
  setCity: PropTypes.func.isRequired,
  city: PropTypes.shape({
    name: PropTypes.string,
    subdomain_name: PropTypes.string,
  }).isRequired,
};

export default CurrentCity;
