import { useMutation } from "react-query";
import HttpClient from "../../httpClient";
import authManagerInstance from "../../auth";

const validateToken = async (authToken) => {
    try {
        const res = await HttpClient.memberLogin.post("/verify_login_link", {
            token: authToken
        });
        return res.data;
    } catch (error) {
        if (error.response) {
            throw error.response.data.message;
        } else {
            throw error;
        }
    }
};

const useValidateToken = (setErrorMessage) => {
    const { mutateAsync: validateTokenAsync, isError, isLoading } = useMutation(
        (token) => validateToken(token),
        {
            onError: (error) => {
                if (setErrorMessage) {
                    setErrorMessage(error);
                }
            },
            onSuccess: (data) => {
                authManagerInstance.setUser(data);
            },
        }
    );
    return { validateToken: validateTokenAsync, isError, isLoading };
}
    
export default useValidateToken;
