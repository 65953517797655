import { Card, ButtonQuickAction } from "@givesafe/design-system-react";
import { useMachine } from "@xstate/react";
import { Link } from "react-router-dom";

import classes from "./index.module.scss";
import {
  registrationMachine,
  RegistrationMachineContext,
} from "./registrationMachine";
import RegistrationSuccess from "./steps/RegistrationSuccess";
import CredentialInputs from "./steps/CredentialInputs";
import CodeInputQuestion from "./steps/CodeInputQuestion";
import MethodInputQuestion from "./steps/MethodInputQuestion";
import NoMethod from "./steps/NoMethod";
import CityQuestion from "./steps/CityQuestion";
import Intro from "./steps/Intro";

const Registration = () => {
  const [current, send] = useMachine(registrationMachine, { devTools: true });

  const shouldShowStepBackButton = current.nextEvents.includes("BACK");

  return (
    <div className={classes.container}>
      <div className={classes.back}>
        <Link to="/login">{"<"} back to login</Link>
      </div>
      <div className={classes.cardWrapper}>
        <div className={classes.backButton}>
          {shouldShowStepBackButton && (
            <ButtonQuickAction
              type="button"
              onClick={() => send("BACK")}
              title="Go back one step"
            >
              {"<"}
            </ButtonQuickAction>
          )}
        </div>
        <Card className={classes.card}>
          <RegistrationMachineContext.Provider value={{ current, send }}>
            <form onSubmit={(e) => e.preventDefault()}>
              {current.matches("intro") && <Intro />}
              {current.matches("cities") && <CityQuestion />}
              {current.matches("noMethod") && <NoMethod />}
              {current.matches("methodInput") && <MethodInputQuestion />}
              {current.matches("codeInput") && <CodeInputQuestion />}
              {current.matches("credentialInputs") && <CredentialInputs />}
              {current.matches("registrationSuccess") && (
                <RegistrationSuccess />
              )}
            </form>
          </RegistrationMachineContext.Provider>
        </Card>
      </div>
    </div>
  );
};

export default Registration;
