import React, { useState } from "react";
import { ButtonCTA1, LoadingCircle } from "@givesafe/design-system-react";
import PropTypes from "prop-types";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@material-ui/styles";
import ReactGA from "react-ga4";

import classes from "./index.module.scss";
import useRequestToken from "../../hooks/api/useRequestToken";

const usePlaceholderStyles = makeStyles(theme => ({
    placeholder: {
      color: "#black"
    }
  }));
  
const Placeholder = ({ children }) => {
    const styles = usePlaceholderStyles();
    return <div className={styles.placeholder}>{children}</div>;
};

Placeholder.propTypes = {
    children: PropTypes.string.isRequired,
};

const ContactInputsComponent = ({ setStage, setCity, city }) => {
    const [contactMethod, setContactMethod] = useState("");
    const [contactValue, setContactValue] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);

    const { requestToken, isLoading } = useRequestToken({ setErrorMessage, setSuccessMessage });

    const handleContinue = async () => {
        ReactGA.event({
            category: "Member",
            action: "Clicked Send Link for contact info"
        });

        const member = {};

        if (contactMethod === "email") {
            member.email = contactValue.toLowerCase().trim();
        }

        if (contactMethod === "phone") {
            member.phone_number = contactValue;
        }

        try {
            await requestToken(member);
        } catch ( error ) {
            console.error("Error:", error);
        }
    };

    const formatPhoneNumber = (input) => {
        if (input === "") return "";
        const cleaned = `${input}`.replace(/\D/g, "");
        let formatted = cleaned.startsWith("1") ? `+${cleaned}` : `+1${cleaned}`;
        formatted = formatted.slice(0, 12);
    
        return formatted;
    }    

    const contactSelectStyle = {
        border: "2px solid rgb(161, 158, 214)",
        width: "215px",
        background: "white",
        borderRadius: "10px",
        color: "#7378e8",
    };

    return (
    <div className={classes.contactInputsDiv}> 
        <div className={classes.selectDiv} style={{textAlign: "center"}}>
            <span className={classes.contactSelectTitle}>
                Choose the contact method you would like your login link to be sent to:
            </span>
            <Select 
                labelId="contact-method-label"
                id="contact-method"
                value={contactMethod}
                onChange={(e) => {
                    setContactMethod(e.target.value);
                    setContactValue("");
                }}                
                displayEmpty
                sx={contactSelectStyle}
                renderValue={
                    contactMethod !== "" ? undefined : () => <Placeholder>Select</Placeholder>
                }
            >
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="phone">Phone Number</MenuItem>
            </Select>
        </div>
        {contactMethod && (
            <div>
                {contactMethod === "email" && (
                    <div className={classes.inputOptionDiv}>
                        <input
                            className={classes.contactInput}
                            type="email"
                            placeholder="example@domain.com"
                            value={contactValue}
                            onChange={(e) => setContactValue(e.target.value)}
                        />
                        {isLoading ? (
                            <LoadingCircle />
                        ) : (
                            <ButtonCTA1 type="button" invert="" onClick={handleContinue}>
                                Send Link
                            </ButtonCTA1>
                        )}
                    </div>
                )}
                {contactMethod === "phone" && (
                    <div className={classes.inputOptionDiv}>
                        <input
                            className={classes.contactInput}
                            type="tel"
                            placeholder="123-456-7890"
                            value={formatPhoneNumber(contactValue)}
                            onChange={(e) => setContactValue(e.target.value)}
                        />
                        {isLoading ? (
                            <LoadingCircle />
                        ) : (
                            <ButtonCTA1 type="button" invert="" onClick={handleContinue}>
                                Send Link
                            </ButtonCTA1>
                        )}
                    </div>
                )}
            </div>
        )}
        <h4 style={{textAlign: "center"}} className={classes.error}>{errorMessage}</h4>
        <h4 style={{textAlign: "center"}} className={classes.success}>{successMessage}</h4>
    </div>
    );
};

ContactInputsComponent.propTypes = {
    setStage: PropTypes.func.isRequired,
    setCity: PropTypes.func.isRequired,
    city: PropTypes.shape({
        name: PropTypes.string,
        subdomain_name: PropTypes.string,
    }).isRequired,
};

export default ContactInputsComponent;
