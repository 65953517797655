// CredentialInputsComponent.jsx
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  ButtonCTA1,
  LoadingCircle
  // ButtonCTA2,
} from "@givesafe/design-system-react";
import PinInput from "react-pin-input";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";

import classes from "./index.module.scss";
import useLogin from "../../hooks/api/useLogin";
import { SAMARITAN_URL } from "../../httpClient";

export const openInNewTab = (subdomain) => {
  const urlEnv = SAMARITAN_URL.includes("staging")
  ? "staging."
  : "";
  const url = `https://${subdomain}.${urlEnv}samaritan.city/beacon/password/new`;
  window.open(url, "_blank", "noreferrer");
};

const CredentialInputsComponent = ({ setStage, city, onWide, errorMessage }) => {
  const history = useHistory();
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const { mutateAsync: login, isError, isLoading } = useLogin();
  const lastNameRef = useRef(null);
  const dobRef = useRef(null);
  const pinRef = useRef(null);

  const dateClass = isError ? classes.errorDate : classes.date;
  const inputClass = isError ? classes.errorInput : classes.input;
  const pinStyle = {
    border: isError ? "2px solid rgb(245, 87, 122)" : "2px solid rgb(161, 158, 214)",
    background: "white",
    borderRadius: "10px"
  };

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const handleInputClick = (ref) => {    
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "center"
      });
    }
  }

  const handleKeyDown = () => {};

  const onSubmit = async (e) => {
    e.preventDefault();
    ReactGA.event({
      category: "Member",
      action: "Clicked log in"
    });
    if ( lastName !== "" && dob !== "" && password !== "" ) {
      const date = new Date(dob.$d);
      const day = date.getDate();
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();      
      const formattedDate = `${date.toDateString().substr(0, 3)}, ${day} ${month} ${year}`;
      try {
        await login({ lastName, formattedDate, password });
        history.push("/me");
      } catch (error) {        
        let loginErrorMessage = "Incorrect login credentials";
        if (error.response && error.response.data) {
          loginErrorMessage = error.response.data.message || errorMessage;
        } else if (error.message) {
          loginErrorMessage = error.message;
        }

        setLoginError(loginErrorMessage);
      }
    }
  };

  return (
    <div className={classes.loginDiv}>
      <form onSubmit={onSubmit} className={classes.loginForm} onFocus={() => onWide()}>
        {errorMessage?.length > 0 && !isError &&
          <div className={classes.error}>{errorMessage}</div>
        }
        {loginError?.length > 0 && 
          <div className={classes.error}>{loginError}</div>
        }
        <div className={classes.credentialsInputDiv}>
          <label htmlFor="lastname" className="font-body">
            Last Name:
          </label>
          <div>
            <input
              type="text"
              name="lastname"
              id="lastname"
              className={inputClass}
              value={lastName} 
              ref={lastNameRef}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleInputClick(e);
              }}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.credentialsInputDiv} ref={dobRef}>
          <label htmlFor="pin" className="font-body">
            Date of Birth:
          </label>
          <div style={{marginTop: "10px"}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              {/* <DatePicker
                onChange={(newValue) => setDOB(newValue)}
              />        */}
              <DateField
                // label="01-11-2000"
                // value={dob}
                onChange={(newValue) => setDOB(newValue)}
                format="MM-DD-YYYY"
                InputProps={{ className: dateClass }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleInputClick(dobRef);
                }}
              />       
            </LocalizationProvider>
          </div>
        </div>
        <div className={classes.credentialsInputDiv}>
          <label htmlFor="pin" className="font-body">
            PIN:
          </label>
          <div
            ref={pinRef}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleInputClick(pinRef);
            }}
            onKeyDown={(e) => handleKeyDown()}
          >
            <PinInput 
              length={4} 
              initialValue=""
              secret
              secretDelay={100} 
              onChange={(value, index) => {}} 
              type="numeric" 
              inputMode="number"
              style={{marginTop: "10px",  padding: "10px, 10px, 10px, 0px"}}  
              inputStyle={pinStyle}
              inputFocusStyle={{borderColor: "#7378e8"}}
              onComplete={(value) => {setPassword(value)}}
              // autoSelect={true}
              // regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
          </div>
        </div>
        <div className={classes.credentialsInputDiv}>
          {isLoading ? 
            <LoadingCircle />
            : 
            <ButtonCTA1 style={{color: "#fbfbff", backgroundColor: "#7378e8", padding: "12px 26px", marginTop: "1rem"}} type="submit" invert="true">Sign-in</ButtonCTA1>
          }
        </div>
        <div className={classes.forgotPasswordDiv}>
          {/* <h5 className={classes.forgotPasswordTitle}>
            Forgot your login credentials?{" "}
          </h5>
          <br/> */}
          <button
            className={classes.forgotPasswordButton}
            type="button"
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
              ReactGA.event({
                category: "Member",
                action: "Clicked Forgot PIN"
              });
              openInNewTab(city?.subdomain_name);
            }}
          >
            Forgot PIN?
          </button>
          <button
            className={classes.forgotPasswordButton}
            type="button"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              ReactGA.event({
                category: "Member",
                action: "Clicked Sign in without PIN"
              });
              setStage("contact-inputs");
            }}
          >
            Sign in without PIN
          </button>
        </div>
      </form>
    </div>
  );
};


CredentialInputsComponent.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  setStage: PropTypes.func.isRequired,
  city: PropTypes.shape({
    name: PropTypes.string,
    subdomain_name: PropTypes.string,
  }).isRequired,
  onWide: PropTypes.func.isRequired,
};

export default CredentialInputsComponent;
