import { useMutation } from "react-query";
import HttpClient from "../../httpClient";

const requestToken = async (member) => {
    try {
        // console.log("HttpClient.member in requestToken", HttpClient.member.defaults.baseURL)
        // console.log("HttpClient.memberLogin in requestToken", HttpClient.memberLogin.defaults.baseURL)

        const res = await HttpClient.memberLogin.post("/request_login_link", {
            member
        });
        return res.data;
    } catch (error) {
        console.log("in error", error)

        if (error.response) {
            throw error.response.data.message;
        } else {
            throw error;
        }
    }
};

const useRequestToken = ({setErrorMessage, setSuccessMessage}) => {
    const { mutateAsync: requestTokenAsync, isError, isLoading } = useMutation(
        (member) => requestToken(member),
        {
            onError: (error) => {
                if (setErrorMessage) {
                    setErrorMessage(error);
                    setSuccessMessage("")
                }
            },
            onSuccess: (data) => {
                setErrorMessage("")
                setSuccessMessage("link successfully sent!")
            },
        }
    );
    return { requestToken: requestTokenAsync, isError, isLoading };
}
    
export default useRequestToken;
