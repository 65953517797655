const formatPhoneNumber = (number) => {
  const strippedNumber = number.replace(/\D/g, "");

  let retStr = "";

  for (let i = 0; i < strippedNumber.length; i += 1) {
    if (i === 3 || i === 6) {
      retStr += "-";
    }
    retStr += strippedNumber[i];
  }
  return retStr;
};

export default formatPhoneNumber;
