import { createSlice } from "@reduxjs/toolkit";

/**
 *  status refers to the status of the authentication state.
 *  status is one of: `idle`, `authenticated`, `expired`
 *
 *  `idle`: not signed in
 *  `authenticated`: signed in.
 *  `expired`: was signed in but session expired
 *
 */

const initialState = {
  data: JSON.parse(localStorage.getItem("user")) || null,
  status: "idle",
};

const { actions, reducer } = createSlice({
  
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
      state.status = "authenticated";
      state.showModal = !action.payload?.member?.member_has_credentials_set;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    signOut: (state) => {
      state.data = null;
      state.status = "idle";
      localStorage.removeItem("user");
    },
    authExpired: (state) => {
      state.status = "expired";
    },
  },
});

export const { setUser, signOut, authExpired } = actions;
export default reducer;
