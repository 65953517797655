import { createContext } from "react";
import { Machine, assign } from "xstate";


export const RegistrationMachineContext = createContext(null);

const validCodeReceiveMethods = ["email", "phoneNumber", /* "proxy" */];

export const registrationMachine = Machine(
  {
    id: "registration",
    initial: "intro",
    context: {
      city: "",
      method: "phoneNumber",
      ...validCodeReceiveMethods.reduce((acc, cur) => ({ ...acc, [cur]: "" }), {}),
      code: "",
      lcpInfo: null
    },
    states: {
      intro: {
        on: {
          START: "cities",
        },
      },
      cities: {
        on: {
          SELECT: {
            target: "methodInput",
            actions: assign({
              city: (_, e) => e.city,
            }),
          },
          BACK: "intro",
        },
      },
      methodInput: {
        on: {
          VALID: {
            target: "codeInput",
            actions: "setMethod"
          },
          SET: {
            actions: assign((_, e) => ({
              [e.method]: e.value
            }))
          },
          NO_METHOD: "noMethod",
          BACK: "cities"
        }
      },
      codeInput: {
        on: {
          VALID: {
            target: "credentialInputs",
            actions: assign({
              code: (_, e) => e.code,
            }),
          },
          BACK: "methodInput",
        },
      },
      credentialInputs: {
        on: {
          SUCCESS: "registrationSuccess",
        },
      },
      registrationSuccess: { type: "final" },

      noMethod: {
        on: {
          BACK: "methodInput",
        },
      },
      // proxySignIn: {
      //   on: {
      //     SIGNED_IN: [{
      //       target: "smSelection",
      //       actions: assign({
      //         lcpInfo: (_, e) => e.lcpInfo
      //       }),
      //       cond: "lcpHasOrg"
      //     },
      //     {
      //       type: "provisionalRegistration",
      //       actions: assign({
      //         method: "provisional"
      //       })
      //     }],
      //     BACK: "intro"
      //   },
      // },
      // smSelection: {
      //   on: {
      //     SELECT: "credentialInputs",
      //     NOT_IN_ORG_LIST: "provisionalRegistration",
      //     BACK: "proxySignIn"
      //   },
      // },
      // provisionalRegistration: {
      //   on: {
      //     BACK: "smSelection",
      //     SUCCESS: "provisionalRegistrationSuccess",
      //   },
      // },
      // provisionalRegistrationSuccess: {
      //   type: "final"
      // },
    },
  },
  {
    actions: {
      setMethod: assign({
        method: (_, e) => e.method,
      }),
    },
    guards: {
      methodIsPhoneNumber: (ctx) => ctx.method === "phoneNumber",
      methodIsEmail: (ctx) => ctx.method === "email",
      validateMethod: (_, e) => validCodeReceiveMethods.includes(e.method),
      lcpHasOrg: (_, e) => !!e.lcpInfo.partner
    },
  }
);
