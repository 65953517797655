import React from "react";
import { ButtonCTA2 } from "@givesafe/design-system-react";
import PropTypes from "prop-types";

import { setSamaritanUrl } from "../../httpClient";
import classes from "./index.module.scss";

const CityButtons = ({ cities, setStage, setCity }) => (
  <div className={classes.buttonGroupContainer}>
    First, select which region you are in:
    <div className={classes.buttonGroup}>
      {cities.map((c) => (
        <ButtonCTA2
            focused
            key={c.name}
            onClick={(e) => {
            e.preventDefault();
            setStage("credential-inputs");
            // setStage("login-options");
            setSamaritanUrl(c.subdomain_name);
            setCity(c);
            }}
        >
            {c.name}
        </ButtonCTA2>
      ))}
    </div>
  </div>
);


export default CityButtons;

const cityShape = PropTypes.shape({
    name: PropTypes.string.isRequired,
});

CityButtons.propTypes = {
    cities: PropTypes.arrayOf(cityShape).isRequired,
    setStage: PropTypes.func.isRequired,
    setCity: PropTypes.func.isRequired,
};
  