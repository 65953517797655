import store from "./redux";
import { setUser, signOut } from "./redux/user";

class AuthManager {
  _user = null;

  constructor() {
    const storedUser = localStorage.getItem("authManagerUser");
    if (storedUser) {
      this._user = JSON.parse(storedUser);
    }
  }

  setUser(user) {
    this._user = user;
    store.dispatch(setUser(user));

    localStorage.setItem("authManagerUser", JSON.stringify(user));
  }

  unsetUser() {
    this._user = null;
    store.dispatch(signOut());

    localStorage.removeItem("authManagerUser");
  }

  get fullName() {
    if (this._user) return `${this._user.name} ${this._user.last_name}`;
    return "";
  }

  get sessionToken() {
    return this._user ? this._user.member.session_token : null;
  }
  
  get apiToken() {
    return this._user ? this._user.member.api_token : null;
  }
}

const authManagerInstance = new AuthManager();

export default authManagerInstance;