import { ButtonCTA1, Input } from "@givesafe/design-system-react";
import { useContext, useEffect, useRef, useState } from "react";
import { RegistrationMachineContext } from "../registrationMachine";

const CredentialInputs = () => {
  const { send } = useContext(RegistrationMachineContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  // const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordsMatch, setPasswordMatchValidation] = useState(false);

  const usernameInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const passwordConfirmRef = useRef(null);

  useEffect(() => {
    setPasswordMatchValidation(password === passwordConfirmation);
  }, [password, passwordConfirmation]);

  const onCreateAccount = () => {
    const usernameValid = usernameInputRef.current?.reportValidity();

    if (usernameValid && passwordsMatch) {
      send("SUCCESS");
    }

    passwordConfirmRef.current?.setCustomValidity(
      "Password fields do not match"
    );
  };

  return (
    <div>
      <div>
        <h1>Your identity has been confirmed</h1>
        <p>
          We have been able to verify your identity with the access code
          you&apos;ve submitted.
        </p>
        <br />
        <p>Pick a username and password to complete your registration</p>
        <br />
      </div>
      <div>
        <label htmlFor="username">
          <div>Username</div>
          <Input
            ref={usernameInputRef}
            autoFocus
            type="text"
            name="username"
            id="username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label htmlFor="password">
          <div>Password</div>
          <Input
            ref={passwordInputRef}
            type="password"
            name="password"
            id="password"
            minLength={6}
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label htmlFor="passwordConfirmation">
          <div>Confirm Password</div>
          <Input
            ref={passwordConfirmRef}
            type="password"
            name="passwordConfirmation"
            id="passwordConfirmation"
            minLength={6}
            required
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
        </label>
      </div>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <ButtonCTA1 type="submit" onClick={onCreateAccount}>
          Create Account
        </ButtonCTA1>
      </div>
    </div>
  );
};
export default CredentialInputs;
