/* eslint-disable import/order */
import React from "react";
import ReactDOM from "react-dom";

// import Helmet from "react-helmet";
import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { Provider } from "react-redux";
import store from "./redux";

import "@givesafe/design-system-react/dist/styles";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import GoogleAnalyticsWithNonce from "./GoogleAnalyticsWithNonce";

// import "./index.scss";
// import "./fonts";
import "./index.scss";

import ReactGA from "react-ga4";

import { QueryClient, QueryClientProvider } from "react-query";
import { pdfjs } from "react-pdf";
// import { inspect } from "@xstate/inspect";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

Sentry.init({
  dsn: "https://06b59f9fd8cd2e85cb3d477c3375e201@o511377.ingest.us.sentry.io/4507142862471168",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  release: process.env.REACT_APP_VERSION,
  normalizeDepth: 5
});

ReactGA.initialize("G-GL475CNE58");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) =>
        // don't retry if response status is 401
        failureCount < 3 && error?.response?.status !== 401,
    },
  },
});

// const scriptTag = `
//   <script src="https://www.googletagmanager.com/gtag/js?id=G-GL475CNE58"></script>
// `;

// if (process.env.NODE_ENV === "development") {
//   inspect({
//     iframe: false,
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
                {/* <script src="https://www.googletagmanager.com/gtag/js?id=G-GL475CNE58" type="text/javascript" />
                <meta
                   httpEquiv="Content-Security-Policy"
                   content="script-src 'self' https://www.googletagmanager.com https://www.google-analytics.com; script-src-elem 'self' https://www.googletagmanager.com;"
                 />  */}
                {/* eslint-disable react/no-danger */}
                {/* <script dangerouslySetInnerHTML={{ __html: scriptTag }} /> */}

                {/* <meta
                  httpEquiv="Content-Security-Policy"
                  content={`
                    script-src 'self' https://www.googletagmanager.com https://www.google-analytics.com;
                  `}      
                 /> */}
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
