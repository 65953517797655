import { useQuery } from "react-query"
import HttpClient from "../../httpClient"


const useCities = () => useQuery("cities", () => fetcher(), {
  cacheTime: 5 * 60 * 1000,
  staleTime: 5 * 60 * 1000,
})

const fetcher = async () => {
  try {
    const res = await HttpClient.v3.get("/cities");
    const data = await res.data;

    return [
      {
        created_at: "2019-04-15T10:35:03.531-07:00",
        id: 1000,
        name: "Washington",
        subdomain_name: "www",
        updated_at: "2019-12-06T16:15:20.492-08:00",
      },
      ...data.cities,
    ];
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export default useCities