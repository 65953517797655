import { createSlice } from "@reduxjs/toolkit";

const { actions, reducer } = createSlice({
  name: "manualSelectUser",
  initialState: "2F3Q-H52MJ",
  reducers: {
    selectUser: (state, action) => action.payload,
  },
});

export const { selectUser } = actions;

export default reducer;
