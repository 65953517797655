import axios from "axios";
import qs from "qs";

import AuthManager from "./auth";
import ReduxStore from "./redux";
import { authExpired } from "./redux/user";


// Required for some reason
axios.defaults.headers = {
  accept: "*/*",
};

axios.defaults.paramsSerializer = (params) => {
  const str = qs.stringify(params, { encode: false, arrayFormat: "brackets" });
  // add custom replace for brackets
  return str.replace(/={}/g, "{}");
};

// const SAMARITAN_DOMAIN = "localhost:3000";
const SAMARITAN_DOMAIN = process.env.REACT_APP_SAMARITAN_DOMAIN;

export const REACT_APP_ENV = process.env.NODE_ENV !== "production" ? "staging" : "production";
console.log("REACT_APP_ENV", REACT_APP_ENV)
console.log("SAMARITAN_DOMAIN", SAMARITAN_DOMAIN)

if (!SAMARITAN_DOMAIN) {
  throw new Error("process.env.SAMARITAN_DOMAIN is not defined");
}

// eslint-disable-next-line import/no-mutable-exports
let SAMARITAN_URL = "";

/**
 * Method tells redux that an authenticated session is expired
 */
const errorAuthInterceptor = (error) => {
  if (error.response.status === 401) {
    ReduxStore.dispatch(authExpired());
  }
  return Promise.reject(error);
};

const member = axios.create({
  baseURL: "",
  // headers: {
  //   "Content-Type": "application/json"
  // }
});

member.interceptors.request.use((config) => ({
  ...config,
  headers: {
    SESSION_TOKEN: `${AuthManager.sessionToken}`,
    Authorization: `Token token=${AuthManager.apiToken}`,
    // SESSION_TOKEN: "UHyZ3bhgx9_MzTn7YSfzfzu14chczxR_",
    // Authorization: `Token token=${"MzpneC1rWDk4eVJEZnA5RG1TdnN6ZEN4US1VaWVlRkttOQ=="}`,
    ...config.headers
  },
}));

member.interceptors.response.use(undefined, errorAuthInterceptor);

const memberLogin = axios.create({
  baseURL: "",
  // headers: {
  //   "Content-Type": "application/json"
  // }
});

const v3 = axios.create({
  baseURL: "",
});

v3.interceptors.request.use((config) => ({
  ...config,
  headers: {
    Authorization: `Token token=${AuthManager.apiToken}`,
    ...config.headers
  },
}));

v3.interceptors.response.use(undefined, errorAuthInterceptor);

export const setSamaritanUrl = (subdomain = "www") => {
  // if (process.env.NODE_ENV === "production" ) {
    // only update samaritan url if app is production
    // SAMARITAN_URL = "https://staging.samaritan.city";
    SAMARITAN_URL = `https://${subdomain}.${SAMARITAN_DOMAIN}`;
    // console.log("SAMARITAN_URL", SAMARITAN_URL)
    memberLogin.defaults.baseURL  = `${SAMARITAN_URL}/members/api`;
    member.defaults.baseURL  = `${SAMARITAN_URL}/members/api`;
    v3.defaults.baseURL  = `${SAMARITAN_URL}/api/v3`;
    // console.log("memberLogin.defaults.baseURL", memberLogin.defaults.baseURL)
    // console.log("v3.defaults.baseURL", v3.defaults.baseURL)
    // Sentry.setTag("city", subdomain);
  // }
};

// set initial values
if (REACT_APP_ENV === "staging") {
  // const storedCity = JSON.parse(window.localStorage.getItem("samaritan::city"));
  // console.log("storedCity", storedCity)
  SAMARITAN_URL = `https://${SAMARITAN_DOMAIN}`;
  memberLogin.defaults.baseURL  = `${SAMARITAN_URL}/members/api`;
  member.defaults.baseURL  = `${SAMARITAN_URL}/members/api`;
  v3.defaults.baseURL  = `${SAMARITAN_URL}/api/v3`;
  // setSamaritanUrl();
  // SAMARITAN_URL = "http://localhost:3000";
} else if (process.env.NODE_ENV === "production") {
  // get stored domain
  const storedCity = JSON.parse(window.localStorage.getItem("samaritan::city"));
  // console.log("storedCity",storedCity)
  setSamaritanUrl(storedCity ? storedCity.subdomain_name : "www");
}

const v2 = axios.create({
  baseURL: `${SAMARITAN_URL}/api/v2`,
});

v2.interceptors.request.use((config) => ({
  ...config,
  params: {
    SESSION_TOKEN: AuthManager.sessionToken,
    ...config.params,
  },
}));

const HttpClient = {
  v2,
  v3,
  member,
  memberLogin
};

export default HttpClient;
export { SAMARITAN_URL };
