import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const RegistrationSuccess = () => {
  const history = useHistory();

  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push("/login");
    }, 4000);

    return () => clearTimeout(timeout);
  }, [history]);

  return (
    <div>
      Your account has been created. You will now to redirected to the login
      page.
      <Link to="/login">
        click here if you aren&apos;t automatically redirected
      </Link>
    </div>
  );
};
export default RegistrationSuccess;
