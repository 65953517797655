import { ButtonCTA2, LoadingCircle } from "@givesafe/design-system-react";
import { useContext } from "react";
import useCities from "../../../hooks/api/useCities";
import classes from "./CityQuestion.module.scss";
import { RegistrationMachineContext } from "../registrationMachine";

const CityQuestion = () => {
  const { send } = useContext(RegistrationMachineContext);
  const cities = useCities();

  const onSelect = (city) => send({ type: "SELECT", city });

  return (
    <div className={classes.citiesContainer}>
      <div>
        <h1>What city are you in?</h1>
        <br />
      </div>
      {cities.isLoading ? (
        <LoadingCircle />
      ) : (
        cities.data.map((city) => (
          <ButtonCTA2
            type="button"
            focused
            onClick={() => onSelect(city)}
            key={city.name}
          >
            {city.name}
          </ButtonCTA2>
        ))
      )}
    </div>
  );
};

export default CityQuestion;
